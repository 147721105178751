import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Experience from './Experience';

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.debug = this.experience.debug;

    this.setInstance();
    this.setFocus();
    this.setOrbitControls();
  }

  setInstance() {
    // Base camera

    if (this.sizes.width < 768) {
      // Mobile Camera Settings
      this.instance = new THREE.PerspectiveCamera(
        50,
        this.sizes.width / this.sizes.height,
        0.1,
        100,
      );
      this.instance.position.set(0, 0.8, 3.5);
    } else {
      // Desktop Camera Settings
      this.instance = new THREE.PerspectiveCamera(
        35,
        this.sizes.width / this.sizes.height,
        0.1,
        100,
      );
      this.instance.position.set(0, 0.8, 3.5);
    }

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Camera');

      this.debugFolder.add(this.instance.position, 'x')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Camera X');
      this.debugFolder.add(this.instance.position, 'y')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Camera Y');
      this.debugFolder.add(this.instance.position, 'z')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Camera Z');
    }

    this.scene.add(this.instance);
  }

  setFocus() {
    this.focus = {
      x: 0,
      y: 0.9,
      z: 0,
    };

    // Debug
    if (this.debug.active) {
      this.debugFolder.add(this.focus, 'y')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Camera Focus Y');
    }
  }

  setOrbitControls() {
    // Controls
    this.controls = new OrbitControls(
      this.instance,
      this.canvas,
    );

    // Debug OrbitControls
    if (this.debug.active) {
      this.controls.enabled = true;
    } else {
      this.controls.enabled = false;
    }

    this.controls.enableDamping = true;
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
    this.instance.lookAt(this.focus.x, this.focus.y, this.focus.z);
  }
}
