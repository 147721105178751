import * as dat from 'lil-gui';
import Stats from 'stats.js';

export default class Debug {
  constructor() {
    this.active = window.location.hash === '#debug';
    // If not active, skip the rest
    if (!this.active) {
      return;
    }

    // Add the ID to the content element to toggle pointer-events in CSS
    document.querySelector('.content').id = 'debug';

    this.ui = new dat.GUI({ closeFolders: true });
    this.stats = new Stats();
    document.body.appendChild(this.stats.dom);
    this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom

    // Button to export gui settings
    const exportSettings = {
      export: () => {
        const settings = JSON.stringify(this.ui.save(), null, 2);
        const blob = new Blob([settings], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'vanback-settings.json';
        a.click();
        URL.revokeObjectURL(url);
      },
    };

    this.ui.add(exportSettings, 'export').name('Export Settings');
  }
}
