import Experience from './Experience';
import EventEmitter from './Utils/EventEmitter';
import Video from './Video';

export default class Interstitial extends EventEmitter {
  constructor() {
    super();
    this.experience = new Experience();
    this.html = this.experience.html;
    this.sizes = this.experience.sizes;
    this.loader = this.experience.loader;

    this.isOpen = true;
    this.isAnimating = false;
    this.activePage = null;

    // Pass the reference to this instance to the video class
    this.video = new Video(this);
    this.children = [...this.html.querySelector('.interstitial__content').children];

    this.interstitial = this.html.querySelector('.interstitial');
    this.setElements();
    this.setListeners();

    // if (this.isOpen && !this.isAnimating) {
    //   this.open('video');
    // }
  }

  setElements() {
    this.elements = {
      open: this.html.querySelectorAll('[data-open]'),
      close: this.html.querySelectorAll('[data-close]'),
      interstitial: this.html.querySelector('.interstitial'),
    };

    // open interstitial on click
    this.elements.open.forEach((element) => {
      element.addEventListener('click', () => {
        // if (this.isOpen || this.isAnimating) return;
        this.open(element.dataset.open);
      });
    });

    // close interstitial on click
    this.elements.close.forEach((element) => {
      element.addEventListener('click', () => {
        // if (!this.isOpen || this.isAnimating) return;
        this.close();
      });
    });

    // close interstitial on press of ESC key
    document.body.addEventListener('keyup', (event) => {
      if (this.isOpen && event.key === 'Escape') {
        this.close();
      }
    });

    // hide interstitial content initially
    this.children.forEach((child) => {
      child.style.display = 'none';
    });
  }

  setListeners() {
    this.interstitial.addEventListener('animationend', (event) => {
      this.isAnimating = false;

      if (event.animationName === 'fade-down') {
        // console.log('animationend fade-down');
      }

      if (event.animationName === 'fade-up') {
        this.html.classList.remove('interstitial-open');
        this.activePage.style.display = 'none';
        this.elements.interstitial.classList.remove('out');

        // reset about/press content
        this.html.querySelector('.about__content').style.display = 'flex';
        this.html.querySelector('.press__content').style.display = 'none';
      }
    });
  }

  open(page) {
    this.html.classList.add('interstitial-open');
    this.elements.interstitial.classList.add('active');
    this.elements.interstitial.dataset.page = page;
    this.isOpen = true;
    this.isAnimating = true;

    // set active page
    this.children.forEach((child) => {
      if (child.dataset.page !== page) return;
      if (page === 'press') return;
      // child.classList.add('active');
      child.style.display = 'block';
      this.activePage = child;
    });

    if (page === 'press') {
      this.html.querySelector('.about__content').style.display = 'none';
      this.html.querySelector('.press__content').style.display = 'flex';
    }

    if (page === 'video') {
      // this.video.restart();
      // this.video.player.playVideo();
      if (this.video.player) {
        this.video.play();
      }

      // hide grain
      this.html.querySelector('.grain').style.display = 'none';
    }

    this.trigger('open');

    // TODO : Accessibility
  }

  close() {
    this.isAnimating = true;
    this.isOpen = false;

    this.elements.interstitial.classList.add('out');
    this.elements.interstitial.classList.remove('active');

    this.html.querySelector('.grain').style.display = 'block';

    this.video.stop();
    this.trigger('close');

    // TODO : Accessibility
  }

  onSiteLoaded() {
    // Open interstitial if user uses direct link to page
    switch (window.location.pathname) {
      case '/privacy-policy':
        this.open('privacy');
        break;
      case '/terms-of-use':
        this.open('terms');
        break;
      case '/about':
        this.open('about');
        break;
      default:
        this.open('video');
        break;
    }
  }
}
