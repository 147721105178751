import Browser from './Broswer';
import EventEmitter from './EventEmitter';

export default class Scroll extends EventEmitter {
  constructor() {
    super();
    this.y = window.scrollY;
    this.height = document.body.clientHeight;

    this.browser = new Browser();

    window.addEventListener('scroll', () => {
      this.y = window.scrollY;
      this.height = document.documentElement.scrollHeight;
      this.trigger('scroll');
    });
  }
}
