/* Vanback - Social Counter */

// Import the required module dependencies
import Request from './Utils/Request';

/**
 * A class to handle social counter.
 */
export default class SocialCounter {
  /**
   * The constructor for the class to handle configuration.
   * @param {Object} options The options used to initialize the instance.
   */
  constructor(options = {}) {
    // Skip if no container is provided
    if (!options.container) {
      return;
    }

    // Cache the options
    this.options = options;

    // Get the reference to the container
    const { container } = options;

    // Fetch the count and format the response for display in the UI
    this.fetchCount((value) => container.innerHTML = this.formatNumber(value));
  }

  /**
   * Method to handle fetching the social count.
   * @param {Function} callback The optional callback for after the count is fetched.
   */
  async fetchCount(callback = () => {}) {
    // Get the count property from the JSON returned from the request
    // TODO: Move URLs to a common config
    const { data } = await Request.get(
      import.meta.env.VITE_GET_SOCIAL_COUNT_API,
    );

    // Pass the count to the callback provided
    return callback(data.count);
  }

  /**
   * Method to handle incrementing the social count.
   * @param {Function} callback The optional callback for after the count is fetched.
   */
  async incrementCount(callback = () => {}) {
    // Increment the count property by one
    // TODO: Move URLs to a common config
    const { success } = await Request.get(
      import.meta.env.VITE_INCREMENT_SOCIAL_COUNT_API,
    );

    // Pass the success to the callback provided
    return callback(success);
  }

  /**
   * Method to handle formatting the number for display, including zero padding.
   * @param {String} value The number to parse
   * @returns
   */
  formatNumber(value = '0') {
    // Specify the max value for the UI (also update the zero pad if changing)
    const max = 99999;
    // Parse the value as a base 10 integer
    const number = window.parseInt(value, 10);

    // If value is larger than display supports
    if (number > max) {
      // TODO: What happens when we go above?
      // Return the max value
      // return max;
      // Return the actual value
      return number;
    }

    // Return the zero padded number
    return `0000${number}`.slice(-5);
  }
}
