import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import Experience from '../Experience';

export default class Text {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.font = this.resources.items.abcBingoFont;

    this.setGeometry();
    this.setMaterial();
    this.setMesh();
  }

  setGeometry() {
    this.geometryTop = new TextGeometry(
      'BRING BACK BASKETBALL BRING BACK BASKETBALL BRING BACK BASKETBALL',
      {
        font: this.font,
        size: 0.45,
        height: 0,
        curveSegments: 12,
      },
    );
    this.geometryTop.center();

    this.geometryBottom = new TextGeometry(
      'BRING BACK BASKETBALL BRING BACK BASKETBALL BRING BACK BASKETBALL',
      {
        font: this.font,
        size: 0.45,
        height: 0,
        curveSegments: 12,
      },
    );
    this.geometryBottom.center();
  }

  setMaterial() {
    this.material = new THREE.MeshBasicMaterial();
    this.material.color = new THREE.Color('#202020');
    this.material.transparent = true;
    this.material.opacity = 0;
  }

  setMesh() {
    this.text = {};
    this.text.position = {
      z: -3.5,
    };

    this.meshTop = new THREE.Mesh(this.geometryTop, this.material);
    this.meshTop.position.set(4.3, 1.4, this.text.position.z);

    this.meshBottom = new THREE.Mesh(this.geometryBottom, this.material);
    this.meshBottom.position.set(-4.42, 0.85, this.text.position.z);

    this.scene.add(this.meshTop, this.meshBottom);

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Text');

      // text color
      this.debugFolder.addColor(this.material, 'color')
        .name('Text Color');

      this.debugFolder.add(this.text.position, 'z')
        .min(-5)
        .max(0)
        .step(0.001)
        .name('Text Z')
        .onChange(() => {
          this.meshTop.position.z = this.text.position.z;
          this.meshBottom.position.z = this.text.position.z;
        });

      this.debugFolder.add(this.material, 'opacity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Text Opacity');
    }
  }
}
