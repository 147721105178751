import Experience from './Experience';

export default class Cursor {
  constructor() {
    this.experience = new Experience();
    this.interstitial = this.experience.interstitial;
    this.scroll = this.experience.scroll;
    this.sizes = this.experience.sizes;
    this.active = false;
    this.setCursor();

    this.interstitial.on('open', () => this.hide(true));
    this.interstitial.on('close', () => this.active = true);
  }

  setCursor() {
    this.element = document.createElement('div');

    // insert svg to element
    this.element.innerHTML = `
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="28" cy="28" r="28" fill="#202020"/>
      <path class="cursor__arrow" fill-rule="evenodd" clip-rule="evenodd" d="M20.4808 23.8978C20.6311 23.7989 20.8174 23.7952 20.9709 23.8879L27.9995 28.1355L35.0281 23.8879C35.1815 23.7952 35.3678 23.7989 35.5182 23.8978C35.6685 23.9967 35.7607 24.1761 35.7607 24.3698V30.2244C35.7607 30.4252 35.6616 30.61 35.5025 30.7062L28.2367 35.0972C28.0888 35.1865 27.9101 35.1865 27.7623 35.0972L20.4965 30.7062C20.3373 30.61 20.2383 30.4252 20.2383 30.2244V24.3698C20.2383 24.1761 20.3304 23.9967 20.4808 23.8978Z" fill="#05CA00"/>
    </svg>
    `;

    this.element.classList.add('cursor', 'hidden');

    document.body.appendChild(this.element);

    document.addEventListener('mousemove', (e) => {
      if (!this.active) return;

      // hide the cursor if it is hovering over text, button, link, or input
      if (
        e.target.closest('a')
        || e.target.closest('button')
        || e.target.closest('input')
        || e.target.closest('p')
        || e.target.closest('h2')
        || e.target.closest('h1')
        || e.target.closest('.page')
        || e.target.closest('select')

      ) {
        this.hide();
        return;
      }

      this.x = e.clientX - 28;
      this.y = e.clientY - 28;
      this.show();
      this.element.style.transform = `translate(${this.x}px, ${this.y}px)`;
    });
  }

  hide(disable) {
    this.element.classList.add('hidden');
    document.body.style.cursor = 'auto';
    if (disable) {
      this.active = false;
    }
  }

  show() {
    // HACK: Workaround for issue where in Safari when video is playing
    if (this.interstitial.isOpen) {
      return;
    }

    this.element.classList.remove('hidden');
    document.body.style.cursor = 'none';
  }

  onScroll() {
    const sectionThree = document.querySelector('.section__three');
    const sectionThreePosition = sectionThree.getBoundingClientRect().top;

    if (sectionThreePosition <= 0) {
      this.hide();
      this.active = false;
    } else {
      this.active = true;
    }
  }
}
