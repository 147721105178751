import * as THREE from 'three';
import Experience from '../Experience';

export default class Floor {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;
    this.sizes = this.experience.sizes;

    this.setGeometry();
    this.setTextures();
    this.setMaterial();
    this.setMesh();
  }

  setGeometry() {
    this.logo = {};

    this.logo.scale = this.sizes.width > 768 ? 2.3 : 1.5;
    this.floorGeometry = new THREE.PlaneGeometry(8, 8);
    this.logoGeometry = new THREE.PlaneGeometry(this.logo.scale, this.logo.scale);
  }

  setTextures() {
    this.textures = {};
    this.floorRepeat = 4;

    // Base Color
    this.textures.color = this.resources.items.asphaltColorTexture;
    this.textures.color.encoding = THREE.sRGBEncoding;
    this.textures.color.repeat.set(this.floorRepeat, this.floorRepeat);
    this.textures.color.wrapS = THREE.RepeatWrapping;
    this.textures.color.wrapT = THREE.RepeatWrapping;

    // Normal Map
    this.textures.normal = this.resources.items.asphaltNormalTexture;
    this.textures.normal.repeat.set(this.floorRepeat, this.floorRepeat);
    this.textures.normal.wrapS = THREE.RepeatWrapping;
    this.textures.normal.wrapT = THREE.RepeatWrapping;

    // Roughness Map
    this.textures.roughness = this.resources.items.asphaltRoughnessTexture;
    this.textures.roughness.repeat.set(this.floorRepeat, this.floorRepeat);
    this.textures.roughness.wrapS = THREE.RepeatWrapping;
    this.textures.roughness.wrapT = THREE.RepeatWrapping;

    // Floor Logo
    this.textures.logo = this.resources.items.floorLogoTexture;
  }

  setMaterial() {
    this.floorMaterial = new THREE.MeshStandardMaterial({
      color: new THREE.Color('#383838'),
      map: this.textures.color,
      normalMap: this.textures.normal,
      roughnessMap: this.textures.roughness,
      roughness: 2,
      normalScale: new THREE.Vector2(0.5, 0.5),
      transparent: false,
      opacity: 1,
    });

    this.logoMaterial = new THREE.MeshStandardMaterial({
      map: this.textures.logo,
      alphaMap: this.textures.normal,
      transparent: true,
      opacity: 1,
    });
  }

  setMesh() {
    this.floorMesh = new THREE.Mesh(this.floorGeometry, this.floorMaterial);
    this.floorMesh.receiveShadow = true;
    this.floorMesh.rotation.x = -Math.PI * 0.5;
    this.floorMesh.position.y = 0;

    this.logoMesh = new THREE.Mesh(this.logoGeometry, this.logoMaterial);
    this.logoMesh.receiveShadow = true;
    this.logoMesh.rotation.x = -Math.PI * 0.5;
    this.logoMesh.position.y = 0.001;

    this.scene.add(this.floorMesh, this.logoMesh);

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Floor');

      // Logo Scale
      this.debugFolder
        .add(this.logo, 'scale')
        .min(0)
        .max(4)
        .step(0.001)
        .name('Logo Scale')
        .onChange(() => {
          this.logoGeometry = new THREE.PlaneGeometry(this.logo.scale, this.logo.scale);
          this.logoMesh.geometry = this.logoGeometry;
        });

      // Logo Opacity
      this.debugFolder
        .add(this.logoMaterial, 'opacity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Logo Opacity');

      // Floor Opacity toggle
      this.debugFolder
        .add(this.floorMaterial, 'transparent')
        .name('Floor Opacity Toggle')
        .onChange(() => {
          this.floorMaterial.needsUpdate = true;
        });

      // Floor opacity
      this.debugFolder
        .add(this.floorMaterial, 'opacity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Floor Opacity');
    }
  }
}
