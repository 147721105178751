/* Vanback - Custom Jersey */

// Import the required module dependencies
import FormValidator from './FormValidator';
import CanvasContext2D from './Utils/CanvasContext2D';
import Request from './Utils/Request';
import Share from './Utils/Share';

/**
 * A class to handle customizing the jersey.
 */
export default class CustomJersey {
  /**
   * The constructor for the custom jersey class to handle configuration.
   * @param {Object} options The options used to initialize the custom jersey.
   */
  constructor(options = {}) {
    // Skip if no form or canvas references provided
    if (!options.form || !options.canvas || !options.display || !options.share) {
      return;
    }

    // Get the options provided
    this.options = options;
    this.form = options.form;
    this.display = options.display;

    // Get the 2D canvas context from the specified canvas element
    this.canvas = new CanvasContext2D(options.canvas);
    this.context = this.canvas.context;

    // Placeholder for the current player's name and number
    this.player = {
      name: options.name || '',
      number: options.number || '',
    };

    // Get references to form elements
    this.submit = this.form.querySelector('button[type=submit]');
    this.open = this.form.querySelector('[data-open]');

    // Create the validator instance for the form
    this.validator = new FormValidator({ form: this.form });

    // Associate the social counter instance
    this.counter = options.counter;

    // On click of the submit button, update the jersey with the name / number
    this.submit.addEventListener('click', this.displayJersey.bind(this));

    // On click of the share button
    [...options.share].forEach((button) => {
      button.addEventListener('click', this.shareJersey.bind(this));
    });
  }

  /**
   * A method to handle drawing the jersey content.
   * @param {String} name
   * @param {String} number
   * @param {Function} callback
   */
  update(name = '', number = '', callback = () => { }) {
    // Reset the canvas
    this.canvas.resetCanvas();

    // Draw the jersey asset first, then the custom name and number
    this.canvas.drawImage(
      '/jersey-share.jpg',
      0,
      -50,
      this.canvas.width,
      this.canvas.height + 100,
      this.drawJerseyCustomization.bind(this, name, number, callback),
    );
  }

  /**
   * A method to handle drawing the player's name and number on the jersey.
   * @param {String} name
   * @param {String} number
   * @param {Function} callback
   */
  async drawJerseyCustomization(name = '', number = '', callback = () => { }) {
    // Cache the values provided
    this.player.name = name;
    this.player.number = number;

    // Draw the user's number
    await this.drawJerseyNumber(number);

    // Draw the user's name
    await this.drawJerseyName(name);

    // Fire the callback
    callback();
  }

  /**
   * A method to handle drawing the player's number on the jersey.
   * @param {String} text
   */
  async drawJerseyNumber(text = '', min = 16, max = 99) {
    // Skip if no text is provided
    if (!text) {
      return;
    }

    // Get the number from the string or fallback to min
    let number = window.parseInt(text, 10) || min;

    // Validate the number to clamp between min and max
    number = number < min ? min : number > max ? max : number;

    await this.drawJerseyNumberPair(number, 0.5, { x: 200, y: 200 }, 8);
    await this.drawJerseyNumberPair(number, 0.06, { x: -280, y: 293 });
  }

  /**
   * A method to handle drawing the player's number on the jersey.
   * @param {String} text
   */
  drawJerseyNumberPair(number, ratio, offset, overlap = 0) {
    // Use a promise to help prevent race considitions when loading/rendering
    return new Promise((resolve, reject) => {
      // Skip if no text is provided
      if (!number) {
        return reject();
      }

      // Load the image before drawing it
      this.loadJerseyNumber(number, (image01, image02) => {
        // Adjust the image widths based on the ratio
        const width01 = image01.width * ratio;
        const width02 = image02.width * ratio;
        // Adjust the image heights based on the ratio
        const height01 = image01.height * ratio;
        const height02 = image02.height * ratio;
        // Specify the left position for each number
        let left01 = ((this.canvas.width - (width01 + width02)) / 2) + offset.x;
        let left02 = left01 + width01;
        // Account for better number overlap
        left01 += overlap;
        left02 -= overlap;
        // Draw the image to the canvas context
        this.context.drawImage(image01, left01, offset.y, width01, height01);
        this.context.drawImage(image02, left02, offset.y, width02, height02);
        // Resolve the promise to help prevent race considitions
        resolve();
      });
    });
  }

  /**
   * A method to handle drawing the player's name on the jersey.
   * @param {String} text
   */
  drawJerseyName(text = '') {
    // Use a promise to help prevent race considitions when loading/rendering
    return new Promise((resolve, reject) => {
      // Skip if no text is provided
      if (!text) {
        return reject();
      }

      // Ensure the text is a string and uppercase
      text = text.toString().toUpperCase();

      // The initial font size to measure text, then adjust accordingly
      let size = 30;

      // The initial radius for the curved path, the adjust based on text width
      let radius = 150;

      // Set the kerning for the player name text
      const kerning = 0;

      // Set the offset based on position of jersey on canvas
      const offset = { x: 200, y: 110 };

      // Set the weight and styles
      const weight = 400;
      const style = '#a4a4a4';

      // Set the initial font, to be adjusted after measuring width
      const family = 'big-noodle-titling';
      let font = `${weight} ${size}px ${family}`;

      // The min/max widths for names to create ratio
      const min = this.canvas.measureText('III', font).width;
      const max = this.canvas.measureText('WWWWWWWWWWWWWWWWWW', font).width;

      // Get the width of the text
      const { width } = this.canvas.measureText(text, font);

      // Get inverse ratio of value between min/max multiplied by the scale
      const ratio = 1 - ((width - min) / (max - min));

      // The size is based on the width of the name
      size += (size * ratio);

      // Set the radius based on the width of the name
      if (width > 350) {
        radius = 120;
      } else if (width > 300) {
        radius = 125;
      } else if (width > 100) {
        radius = 130;
      } else if (width > 50) {
        radius = 130;
      }

      // Update the font with the latest size
      font = `${weight} ${size}px ${family}`;

      // Set the position for the center of the text
      const position = {
        x: (this.canvas.width / 2) - radius + offset.x,
        y: offset.y,
      };

      // Draw the curved text on the canvas
      this.context.drawImage(
        this.canvas.drawTextCurved(text, radius, font, size, style, kerning),
        position.x,
        position.y,
      );

      // Resolve the promise to help prevent race considitions
      resolve();
    });
  }

  /**
   * Method to handle rendering and displaying the custom jersey asset.
   */
  displayJersey(event) {
    // Prevent default behaviour
    event.preventDefault();

    // Get references to form elements
    const name = this.form.elements.name.value;
    const number = this.form.elements.number.value;

    // Skip if the name for the jersey is not valid
    if (!this.validator.validateForm()) {
      return false;
    }

    // Toggle the intersitital
    this.open.click();

    // Update the name and number on the jersey
    this.update(name, number, () => {
      // Get the Base64 image data for the source on the image element
      let src = '';
      // Show the 1:1 image on mobile and small tablet and 2:1 image on larger
      if (window.innerWidth <= 768) {
        src = this.cropJersey().toDataURL();
      } else {
        src = this.canvas.toDataURL();
      }
      // Append the jersey image to the social interstitial
      this.display.innerHTML = `<img src="${src}" alt="${name} - ${number}">`;
    });
  }

  /**
   * Method to handle sharing the custom jersey.
   */
  async shareJersey(event) {
    // Prevent default behaviour
    event.preventDefault();

    // Get the network from the button attributes
    const network = event.target.dataset.share;

    // TODO: Move content and config to a seperate class to include

    // Set the URL for sharing
    let url = 'https://www.vanback.ca/';

    // Set the text for sharing
    const text = [
      "I'm no billionaire, but I'm 100% in.",
      "Let's get team #VANBACK together and make this happen!",
      '#BRINGBACKBASKETBALL',
    ].join(' ');

    // Increment the social share counter
    this.counter && this.counter.incrementCount();

    // TODO: Move options and URLs to a common config
    const params = `name=${this.player.name}&number=${this.player.number}`;
    const host = import.meta.env.VITE_SOCIAL_UPLOAD_API;
    const endpoint = `${host}?${params}`;

    // Switch the functionality based on the network.
    switch (network) {
      case 'twitter':
      case 'facebook':
        const image = this.canvas.toDataURL();
        // Upload the share image to S3 first
        const response = await Request.put(endpoint, image, { json: false });
        // Parse the data from the response as JSON (since we passed false above)
        const { success, data } = await response.json();
        // Get the URL from the response or use the default above
        url = success ? data : url;
        // Share the URL to the respective network
        Share.url(network, url, text);
        break;
      case 'instagram':
      default:
        this.downloadJersey();
    }
  }

  /**
   * Method to handle downloading the custom jersey asset.
   */
  downloadJersey() {
    // Get the image cropped to a 1:1 ratio
    const cropped = this.cropJersey();

    // Create the temporary anchor element
    const link = document.createElement('a');

    // Specify the download attribute and give the file a name
    link.download = 'vanback-jersey.png';

    // Set the Base64 image data as the href on the anchor element
    link.href = cropped.toDataURL();

    // Trigger a click event on the anchor element to invoke the download
    link.click();
  }

  /**
   * Method to handle cropping the custom jersey asset to a square
   */
  cropJersey() {
    // Create the temporary canvas element
    const canvas = new CanvasContext2D(null, {
      width: this.canvas.height,
      height: this.canvas.height,
    });

    // Draw the image on the square canvas
    canvas.context.drawImage(this.context.canvas, -400, 0);

    // Return the element for use
    return canvas;
  }

  /**
   * A method to handle loading the player's number assets.
   * @param {Number} number
   * @param {Function} callback
   */
  loadJerseyNumber(number, callback = () => { }) {
    // Get the first and second digits from the number
    const [first, second] = number.toString().split('');

    // Load the first image
    this.canvas.loadImage(this.formatJerseyNumberURL(first), (image01) => {
      // Load the second image and fire the callback
      this.canvas.loadImage(this.formatJerseyNumberURL(second), (image02) => {
        // Fire the callback, passing the loaded image
        callback(image01, image02);
      });
    });
  }

  /**
   * A method to handle formatting the URL for the jersey number.
   * @param {Number} number
   */
  formatJerseyNumberURL(number) {
    // TODO: Should this be moved to a common config?
    const base = '/textures/numbers/';
    const prefix = '/vanback-jersey-number-';
    const suffix = '.png';

    // Format the url to match /textures/numbers/0/vanback-jersey-number-0.png
    return base + number + prefix + number + suffix;
  }
}
