import * as THREE from 'three';

import Experience from '../Experience';

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.debug = this.experience.debug;

    this.resources = this.experience.resources;

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Environment');
    }

    this.setAmbientLight();
    this.setSunLight();
    this.setSpotLight();
    // this.setPointight();
    // this.setEnvironmentMap();
  }

  setAmbientLight() {
    this.ambientLight = new THREE.AmbientLight('#ffffff', 0.22);
    this.scene.add(this.ambientLight);

    if (this.debug.active) {
      this.debugFolder
        .add(this.ambientLight, 'intensity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Ambient Light');
    }
  }

  setSunLight() {
    this.sunLight = new THREE.DirectionalLight('#ffffff', 2.5);
    // this.sunLight.castShadow = true;
    this.sunLight.shadow.camera.top = 3;
    this.sunLight.shadow.camera.right = 3;
    this.sunLight.shadow.camera.bottom = -3;
    this.sunLight.shadow.camera.left = -3;
    this.sunLight.shadow.camera.near = -2;
    this.sunLight.shadow.camera.far = 5;
    this.sunLight.shadow.mapSize.set = (1024, 1024);
    this.sunLight.shadow.normalBias = 0.05;
    this.sunLight.shadow.radius = 8;
    this.sunLight.shadow.blurSamples = 30;
    this.sunLight.position.set(0, 2, 0.9);
    this.scene.add(this.sunLight);

    if (this.debug.active) {
      this.debugFolder
        .add(this.sunLight, 'intensity')
        .min(0)
        .max(15)
        .step(0.001)
        .name('Sunlight Intensity');

      this.debugFolder
        .add(this.sunLight.position, 'x')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Sunlight X');

      this.debugFolder
        .add(this.sunLight.position, 'z')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Sunlight Z');
    }
  }

  setSpotLight() {
    this.spotLight = new THREE.SpotLight(0xffffff, 4, 40, Math.PI * 0.1);
    this.spotLight.position.set(0, 4.4, 0.5);
    this.spotLight.castShadow = true;
    this.spotLight.penumbra = 0.8;
    this.scene.add(this.spotLight);

    this.spotLightHelper = new THREE.SpotLightHelper(this.spotLight);
    this.scene.add(this.spotLightHelper);
    this.spotLightHelper.visible = false;

    if (this.debug.active) {
      this.debugFolder
        .add(this.spotLight, 'intensity')
        .min(0)
        .max(15)
        .step(0.001)
        .name('Spotlight Intensity');

      this.debugFolder
        .add(this.spotLight.position, 'y')
        .min(0)
        .max(10)
        .step(0.001)
        .name('Spotlight Y');

      this.debugFolder
        .add(this.spotLight.position, 'z')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Spotlight Z');

      this.debugFolder
        .add(this.spotLightHelper, 'visible')
        .name('Spotlight Helper');

      this.debugFolder
        .add(this.spotLight, 'penumbra')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Spotlight Penumbra');
    }
  }

  setEnvironmentMap() {
    this.environmentMap = {};
    this.environmentMap.intensity = 0;
    this.environmentMap.texture = this.resources.items.environmentMapTexture;
    this.environmentMap.texture.encoding = THREE.sRGBEncoding;

    this.scene.environment = this.environmentMap.texture;

    this.environmentMap.updateMaterial = () => {
      this.scene.traverse((child) => {
        // Only update meshes with standard material
        if (
          child instanceof THREE.Mesh
          && child.material instanceof THREE.MeshStandardMaterial
        ) {
          child.material.envMapIntensity = this.environmentMap.intensity;
          child.material.needsUpdate = true;
        }
      });
    };

    this.environmentMap.updateMaterial();

    // Debug
    if (this.debug.active) {
      this.debugFolder
        .add(this.environmentMap, 'intensity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Env Intensity')
        .onChange(this.environmentMap.updateMaterial);
    }
  }

  setPointLight() {
    this.pointLight = new THREE.PointLight(0xffffff, 1, 10, 2);
    this.pointLight.position.set(0, 2, 0);
    this.scene.add(this.pointLight);

    // Debug
    if (this.debug.active) {
      this.debugFolder.add(this.pointLight, 'intensity')
        .min(0)
        .max(15)
        .step(0.001)
        .name('Intensity');

      this.debugFolder.add(this.pointLight.position, 'x')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('X');

      this.debugFolder.add(this.pointLight.position, 'y')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Y');

      this.debugFolder.add(this.pointLight.position, 'z')
        .min(-10)
        .max(10)
        .step(0.001)
        .name('Z');
    }
  }
}
