import * as THREE from 'three';
import Sizes from './Utils/Sizes.js';
import Time from './Utils/Time.js';
import Scroll from './Utils/Scroll.js';
import Camera from './Camera.js';
import Renderer from './Renderer.js';
import World from './World/World.js';
import Resources from './Utils/Resources.js';
import Debug from './Utils/Debug.js';
import sources from './sources.js';
import Loader from './Loader.js';
import Interstitial from './Interstitial.js';
import PlayersPledge from './PlayersPledge.js';
import CustomJersey from './CustomJersey.js';
import SocialCounter from './SocialCounter.js';
import Cursor from './Cursor.js';
import Screenshot from './Utils/Screenshot.js';

// Singleton
let instance = null;

export default class Experience {
  constructor(canvas) {
    if (instance) {
      return instance;
    }

    instance = this;

    // Options
    this.canvas = canvas;

    // Setup
    this.debug = new Debug();
    this.scroll = new Scroll();
    this.sizes = new Sizes();
    this.time = new Time();
    this.html = document.documentElement;
    this.resources = new Resources(sources);
    this.loader = new Loader();
    this.interstitial = new Interstitial();
    this.scene = new THREE.Scene();
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();
    this.pledge = new PlayersPledge();
    this.counter = new SocialCounter({
      container: document.querySelector('.counter .count'),
    });
    this.jersey = new CustomJersey({
      form: document.querySelector('.custom-jersey__form'),
      canvas: document.querySelector('.custom-jersey__canvas'),
      display: document.querySelector('.social__jersey'),
      share: document.querySelectorAll('.social__share'),
      counter: this.counter,
    });
    this.screenshot = new Screenshot();
    this.cursor = new Cursor();

    // Events

    this.sizes.on('resize', () => this.resize());

    this.time.on('tick', () => this.update());

    this.loader.on('loaded', () => this.interstitial.onSiteLoaded());

    this.scroll.on('scroll', () => this.onScroll());

    this.interstitial.on('open', () => this.world.open());
    this.interstitial.on('close', () => this.world.close());
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
  }

  update() {
    this.camera.update();
    this.renderer.update();
  }

  onScroll() {
    // this.world.onScroll();
    this.cursor.onScroll();
  }
}
