/* eslint-disable no-undef */
import Experience from './Experience';

export default class Video {
  constructor(interstitial) {
    this.experience = new Experience();
    this.interstitial = interstitial;
    this.sizes = this.experience.sizes;
    this.html = this.experience.html;
    this.loader = this.interstitial.loader;

    this.initializeAPI();
    this.setControls();

    this.loader.on('loaded', () => {
      this.play();
    });
  }

  initializeAPI() {
    window.onYouTubeIframeAPIReady = this.setVideo.bind(this);

    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';

    const first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
  }

  setVideo() {
    this.player = new YT.Player('video-embed', {
      height: '100%',
      width: '100%',
      videoId: 'zlNUvr1DXow',
      playerVars: {
        autoplay: 0,
        mute: 1,
        playsinline: 1,
        controls: 0,
        loop: 0,
        rel: 0,
        modestbranding: 1,
        disablekb: 1,
        enablejsapi: 1,
      },
      events: {
        // 'onReady': onPlayerReady,
        onStateChange: this.onStateChange.bind(this),
      },
    });
  }

  setControls() {
    this.controls = {
      unmute: this.html.querySelector('[data-unmute]'),
      pause: this.html.querySelector('[data-pause]'),
      progress: this.html.querySelector('#video-progress'),
    };

    // Events
    this.controls.pause.addEventListener('click', () => this.pause());
    this.controls.unmute.addEventListener('click', () => this.mute());
  }

  play() {
    if (!this.player || !this.player.playVideo) {
      return console.warn("video player is not available");
    }

    if (this.isPlaying) {
      return this.pause();
    }

    this.player.playVideo();
    this.updateProgress();
    // this.controls.pause.innerHTML = 'Pause';

    document.querySelector('.video__controls--pause').classList.add('active');
    document.querySelector('.video__controls--play').classList.remove('active');

    this.player.addEventListener('onStateChange', () => {
      this.updateProgress();
    });

    return true;
  }

  pause() {
    if (!this.player || !this.player.pauseVideo) {
      return console.warn("video player is not available");
    }

    if (!this.isPlaying) {
      return this.play();
    }

    this.player.pauseVideo();
    // this.controls.pause.innerHTML = 'Play';

    document.querySelector('.video__controls--play').classList.add('active');
    document
      .querySelector('.video__controls--pause')
      .classList.remove('active');

    return true;
  }

  stop() {
    if (!this.player || !this.player.stopVideo) {
      return console.warn("video player is not available");
    }

    this.player.stopVideo();
  }

  skip() {
    this.stop();
    this.interstitial.close();
  }

  mute() {
    if (!this.player || !this.player.isMuted) {
      return console.warn("video player is not available");
    }

    if (this.player.isMuted()) {
      this.unmute();
      return;
    }

    this.player.mute();

    document.querySelector('.video__controls--mute').classList.add('active');
    document
      .querySelector('.video__controls--unmute')
      .classList.remove('active');
  }

  unmute() {
    if (!this.player || !this.player.isMuted) {
      return console.warn("video player is not available");
    }

    if (!this.player.isMuted()) {
      this.mute();
      return;
    }

    this.player.unMute();

    document.querySelector('.video__controls--mute').classList.remove('active');
    document.querySelector('.video__controls--unmute').classList.add('active');
  }

  onStateChange(event) {
    if (!this.player || !this.player.getPlayerState) {
      return console.warn("video player is not available");
    }

    this.isPlaying = this.player.getPlayerState() === YT.PlayerState.PLAYING;

    switch (event.data) {
      case YT.PlayerState.ENDED:
        this.skip();
        break;
      case YT.PlayerState.PLAYING:
        document
          .querySelector('.video__controls--pause')
          .classList.add('active');
        document
          .querySelector('.video__controls--play')
          .classList.remove('active');
        break;
      case YT.PlayerState.PAUSED:
        document
          .querySelector('.video__controls--play')
          .classList.add('active');
        document
          .querySelector('.video__controls--pause')
          .classList.remove('active');
        break;

      default:
        break;
    }
  }

  updateProgress() {
    if (YT.PlayerState.PLAYING) {
      window.setInterval(() => {
        // const progress = this.player.getCurrentTime() / this.player.getDuration();
        // this.controls.progress.setAttribute('value', progress * 100);

        // update time
        const currentTime = this.player.getCurrentTime();
        const minutes = Math.floor(currentTime / 60);
        const seconds = Math.floor(currentTime - minutes * 60);

        // make sure both values are two digits
        const formattedMinutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
        const formattedSeconds = `${seconds < 10 ? `0${seconds}` : seconds}`;

        document.querySelector(
          '.video__time--current',
        ).innerHTML = `${formattedMinutes}:${formattedSeconds}`;
      }, 100);

      if (YT.PlayerState.PAUSED || YT.PlayerState.ENDED) {
        window.clearInterval();
      }
    }
  }
}
