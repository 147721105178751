import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Experience from './Experience';

export default class Timeline {
  constructor(camera, jersey, team, text, fog) {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.config({
      ignoreMobileResize: true,
    });

    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.interstitial = this.experience.interstitial;

    this.camera = camera;
    this.jersey = jersey;
    this.team = team;
    this.text = text;
    this.fog = fog;
    this.setTimeline();

    // fix for scroll trigger position shifting when toolbars are shown/hidden
    // this mainly happens on mobile devices when the interstitial is open
    this.interstitial.on('open', () => {
      ScrollTrigger.disable(false);
    });

    this.interstitial.on('close', () => {
      ScrollTrigger.enable(false);
      ScrollTrigger.refresh();
    });
  }

  setTimeline() {
    const tl = gsap.timeline();

    gsap.to(this.text.material, {
      opacity: 1,
      scrollTrigger: {
        trigger: '.section__two',
        start: '70% top',
        end: 'bottom top',
        endTrigger: '.section__three',
        scrub: true,
        // markers: true,
      },
    });

    // fade out intro
    tl
      .to('.section__one', {
        opacity: 0,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: 1,
          pin: true,
        // markers: true,
        },
      })

      // move jersey to the center
      .to(this.jersey.model.position, {
        z: 0,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      })

      // fade out team jerseys
      .to(this.team.team, {
        opacity: 0,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: true,
          // markers: true,
        },
        onUpdate: () => {
          this.team.jerseyGroup.traverse((child) => {
            if (child.isMesh) {
              child.material.opacity = this.team.team.opacity;
            }
          });
        },

        // even though the opacity is 0, the team jerseys are causing visual issues with the text
        onComplete: () => {
          this.team.jerseyGroup.visible = false;
        },
      })

      // move team jerseys out
      .to(this.team.team, {
        radius: 4.2,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
        onUpdate: () => {
          // change the radius of the team
          for (let i = 0; i < this.team.team.count; i++) {
            const angle = -(i / this.team.team.count) * Math.PI * 2;
            this.team.jerseyClone = this.team.jerseyGroup.children[i];
            this.team.jerseyClone.position.x = Math.cos(angle) * this.team.team.radius;
            this.team.jerseyClone.position.z = Math.sin(angle) * this.team.team.radius;
          }
        },
      })

      // rotate jersey
      .to(this.jersey.model.rotation, {
        y: Math.PI * 2,
        scrollTrigger: {
          trigger: '.section__two',
          start: 'top center',
          end: 'bottom top',
          scrub: 1,
          pin: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },

      })

      // move camera closer to jersey
      .to(this.camera.instance.position, {
        z: 2.2,
        y: 0.9,
        scrollTrigger: {
          trigger: '.section__two',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },

        // to make sure the jersey is visible when scrolling back up
        onReverseComplete: () => {
          this.team.jerseyGroup.visible = true;
        },
      })

      // adjust fog
      .to(this.fog, {
        near: 0.5,
        scrollTrigger: {
          trigger: '.section__two',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      })

      // Move jersey up out of frame
      .to(this.jersey.model.position, {
        y: 1,
        immediateRender: false,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top 85%',
          end: 'top top',
          scrub: true,
          // pin: true,
          // markers: true,
        },

        onComplete: () => {
          this.jersey.model.visible = false;
        },
      })

      // move camera back
      .to(this.camera.instance.position, {
        z: 3.5,
        immediateRender: false,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top 85%',
          end: 'top top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      })

      .to(this.fog, {
        near: 3.5,
        immediateRender: false,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top 85%',
          end: 'top top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      })

      // fade in join the team section
      .from('.section__three', {
        opacity: 0,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top top',
          end: 'bottom center',
          scrub: true,
          pin: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      })

      // fade in team jerseys
      .to(this.team.team, {
        opacity: 1,
        immediateRender: false,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: true,
        },
        onUpdate: () => {
          this.team.jerseyGroup.traverse((child) => {
            if (child.isMesh) {
              child.material.opacity = this.team.team.opacity;
            }
          });
        },

        // to make sure the jersey is visible when scrolling back up
        onReverseComplete: () => {
          this.jersey.model.visible = true;
        },

        onStart: () => {
          this.team.jerseyGroup.visible = true;
        },
      })

      // move team jerseys in
      .to(this.team.team, {
        radius: 2.2,
        immediateRender: false,
        scrollTrigger: {
          trigger: '.section__three',
          start: 'top center',
          end: 'center top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
        onUpdate: () => {
          // change the radius of the team
          for (let i = 0; i < this.team.team.count; i++) {
            const angle = -(i / this.team.team.count) * Math.PI * 2;
            this.team.jerseyClone = this.team.jerseyGroup.children[i];
            this.team.jerseyClone.position.x = Math.cos(angle) * this.team.team.radius;
            this.team.jerseyClone.position.z = Math.sin(angle) * this.team.team.radius;
          }
        },
        onReverseComplete: () => {
          this.team.jerseyGroup.visible = false;
        },
      })

      // fade out team jerseys
      .to(this.team.team, {
        opacity: 0,
        scrollTrigger: {
          trigger: '.section__four',
          start: 'top bottom',
          end: '50% 80%',
          scrub: true,
          // markers: true,
        },
        onUpdate: () => {
          this.team.jerseyGroup.traverse((child) => {
            if (child.isMesh) {
              child.material.opacity = this.team.team.opacity;
            }
          });
        },

        // even though the opacity is 0, the team jerseys are causing visual issues with the text
        onComplete: () => {
          this.team.jerseyGroup.visible = false;
        },
      })

      // move team jerseys out
      .to(this.team.team, {
        radius: 4.2,
        scrollTrigger: {
          trigger: '.section__four',
          start: 'top bottom',
          end: '50% 80%',
          scrub: true,
          // markers: true,
        },
        onUpdate: () => {
          // change the radius of the team
          for (let i = 0; i < this.team.team.count; i++) {
            const angle = -(i / this.team.team.count) * Math.PI * 2;
            this.team.jerseyClone = this.team.jerseyGroup.children[i];
            this.team.jerseyClone.position.x = Math.cos(angle) * this.team.team.radius;
            this.team.jerseyClone.position.z = Math.sin(angle) * this.team.team.radius;
          }
        },
      })

    // .to('.section__three', {
    //   opacity: 0,
    //   immediateRender: false,
    //   scrollTrigger: {
    //     trigger: '.section__four',
    //     start: 'top center',
    //     end: 'top bottom',
    //     scrub: 1,
    //     pin: true,
    //     markers: true,
    //   },
    // })

      .from('.section__four', {
        opacity: 1,
        scrollTrigger: {
          trigger: '.section__four',
          start: 'top 15%',
          end: 'center top',
          scrub: true,
          // pin: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
        onReverseComplete: () => {
          this.team.jerseyGroup.visible = true;
        },
      })

      // fade in last section
      .from('.section__five', {
        opacity: 0,
        scrollTrigger: {
          trigger: '.section__five',
          endTrigger: '.section__six',
          start: 'top top',
          end: '20% top',
          scrub: true,
          pin: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      });

    // Responsive animations
    if (this.sizes.width < 768) {
      // scale jersey
      tl.to(this.jersey.model.scale, {
        x: 1.5,
        y: 1.5,
        z: 1.5,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      }).to(this.jersey.model.position, {
        y: -1.12,
        scrollTrigger: {
          trigger: '.section__two',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      });
    } else {
      tl.to(this.jersey.model.scale, {
        x: 1.7,
        y: 1.7,
        z: 1.7,
        scrollTrigger: {
          trigger: '.section__one',
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        },
      }).to(this.jersey.model.position, {
        y: -1.4,
        scrollTrigger: {
          trigger: '.section__two',
          start: 'top center',
          end: 'bottom top',
          scrub: true,
          // markers: {
          //   startColor: 'yellow',
          //   endColor: 'purple',
          // },
        },
      });
    }

    gsap.to('.logo__image', {
      rotate: -720,
      scrollTrigger: {
        trigger: '.content',
        start: 'top top',
        end: 'bottom top',
        scrub: 2,
      },
    });

    // animate text on repeat
    gsap.to(this.text.meshTop.position, {
      x: -7,
      repeat: -1,
      yoyo: true,
      duration: 30,
      ease: 'none',
    });

    gsap.to(this.text.meshBottom.position, {
      x: 7,
      repeat: -1,
      yoyo: true,
      duration: 30,
      ease: 'none',
    });
  }
}
