import EventEmitter from './EventEmitter';
import Experience from '../Experience';

export default class Sizes extends EventEmitter {
  constructor() {
    super();
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.pixelRatio = Math.min(window.devicePixelRatio, 2);

    // HACK - account for mobile toolbar height
    if (this.width < 768) {
      this.height = this.canvas.clientHeight;
    }

    // Resize
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.pixelRatio = Math.min(window.devicePixelRatio, 2);

      // HACK - account for mobile toolbar height
      if (this.width < 768) {
        this.height = this.canvas.clientHeight;
      }

      this.trigger('resize');
    });
  }
}
