/**
 * A class to handle sharing via email, sms and social media.
 */
class Share {

  /**
   * Share a URL to the specified network.
   * @param {String} network  The identifier of the network/protocol to use.
   * @param {String} url  The URL to share / send.
   * @param {String} text  The optional text to add to the share.
   * @param {String} target  The window target for opening the link.
   */
  url(network, url, text, target = "_blank") {

    switch (network) {
      case "facebook":
        return this.open(this.facebook(url), target);
      case "twitter":
        return this.open(this.twitter(url, text), target);
      case "linkedin":
        return this.open(this.linkedin(url, text), target);
      case "email":
        return this.open(this.email(url, text), target);
      case "sms":
        return this.open(this.sms(url, text), target);
    }

  }

  /**
   * Open the URL and optional target.
   * @param {String} url  The URL to share / send.
   * @param {String} target  The window target for opening the link.
   */
  open(url, target) {

    if (!window.open(url, target)) {
      window.location.href = url;
    }

  }

  /**
   * Method to generate share link for a URL via Facebook.
   * @param {String} url  The URL to share / send.
   * @returns {String} The social share URL with the provided values.
   */
  facebook(url) {

    url = window.encodeURIComponent(url);

    return "https://www.facebook.com/sharer.php?u=" + url;

  }

  /**
   * Method to generate share link for a URL and optional text via Twitter.
   * @param {String} url  The URL to share / send.
   * @param {String} text  The text to share / send.
   * @returns {String} The social share URL with the provided values.
   */
  twitter(url, text) {

    url = window.encodeURIComponent(url);

    let link = "https://twitter.com/intent/tweet?url=" + url;

    if (text) {
      link += "&text=" + window.encodeURIComponent(text);
    }

    return link;

  }

  /**
   * Method to generate share link for a URL and title/summary via LinkedIn.
   * @param {String} url  The URL to share / send.
   * @param {String} title  The title text to share / send.
   * @param {String} summary  The summary text to share / send.
   * @returns {String} The social share URL with the provided values.
   */
  linkedin(url, title, summary) {

    url = window.encodeURIComponent(url);

    let link = "https://www.linkedin.com/shareArticle?mini=true&url=" + url;

    if (title) {
      link += "&title=" + window.encodeURIComponent(title);
    }

    if (summary) {
      link += "&summary=" + window.encodeURIComponent(summary);
    }

    return link;

  }

  /**
   * Method to generate share link for a URL via email, with subject/recipient.
   * @param {String} body  The body of the message to send.
   * @param {String} subject  The subject line of the message to send.
   * @param {String} to  The recipient of the message.
   * @returns {String} The social share URL with the provided values.
   */
  email(body, subject, to) {

    let link = "mailto:";

    if (to) {
      link += to;
    }

    link += "?";

    if (subject) {
      link += "subject=" + window.encodeURIComponent(subject);
    }

    if (body) {
      link += "body=" + window.encodeURIComponent(body);
    }

    return link;

  }

  /**
   * Method to generate share link for a URL via sms, with optional recipient.
   * @param {String} body  The body of the message to send.
   * @param {String} to  The recipient of the message.
   * @returns {String} The social share URL with the provided values.
   */
  sms(body, to) {

    let link = "sms:";

    if (to) {
      link += to;
    }

    link += "?";

    if (body) {
      link += "body=" + window.encodeURIComponent(body);
    }

    return link;

  }

}

// Export the class as a singleton / static class by creating an instance
export default new Share();
