/* Canvas 2D Context */

/**
 * A class to handle canvas elements and the 2D canvas context.
 */
export default class CanvasContext2D {

  /**
   * The constructor for the canvas context class to handle configuration.
   * @param {Object} options The options used to initialize the custom jersey.
   */
  constructor(canvas, options = {}) {

    // Cache the options provided
    this.options = options;

    // Get the references to the canvas element and 2D context
    this.canvas = canvas || document.createElement("canvas");
    this.context = this.canvas.getContext("2d");

    // Get the width and height of the canvas
    this.width = this.options.width || this.canvas.offsetWidth;
    this.height = this.options.height || this.canvas.offsetHeight;

    // Set the actual width / height based on the specified size
    // TODO: Handle retina sizing
    this.canvas.width = this.width;
    this.canvas.height = this.height;

  }

  /**
   * A method to handle drawing a line of text on the canvas.
   * @param {String} text
   * @param {Object} position
   * @param {String} font
   * @param {String} style
   */
  drawText(text, position, font, style) {

    // Skip if no text is provided
    if (!text) {
      return;
    }

    // Ensure the text is a string
    text = text.toString();

    // Set the font size and font family
    this.context.font = font;

    // Set the fill style based on the specified styling
    this.context.fillStyle = style;

    // Draw the text at the specified coordinates
    this.context.fillText(text, position.x, position.y);

  }

  /**
   * A method to handle drawing a curved line of text on the canvas.
   * @param {String} text  The text to render.
   * @param {Number} radius  The size of the curved circle to render along.
   * @param {String} font  The font family, size and weight.
   * @param {Number} size  The size to render at.
   * @param {String} style  The font style to use.
   * @param {Number} kerning  The kerning, positive or negative pixel values.
   * @returns {HTMLCanvasElement}  The text rendered on a temporary canvas.s
   */
  drawTextCurved(text, radius, font, size, style = "#000", kerning = 0) {

    // Skip if no text is provided
    if (!text) {
      return;
    }

    // Ensure the text is a string
    text = text.toString();

    // Reverse the letters for rendering
    text = text.split("").reverse().join("");

    // Cache the length of the text for use below
    const { length } = text;

    // Specify the configuration for the text
    let diameter = radius * 2;
    let offset = radius - size;
    let align = "center";
    let clockwise = align === "right" ? 1 : -1;
    let angle = 0;

    // Create a temporary canvas element to render the text to
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Configure the temporary canvas
    canvas.width = diameter;
    canvas.height = diameter;
    context.fillStyle = style;
    context.font = font;

    // Move to the center to draw from there
    context.translate(radius, radius);
    context.textBaseline = "middle";
    context.textAlign = "center";

    // To align to center, rotate half of angle
    for (let index = 0; index < length; index++) {
      let { width } = context.measureText(text[index]);
      let kerned = width + (index === length - 1 ? 0 : kerning);
      angle += (kerned / offset) / 2 * -clockwise;
    }

    // Rotate to the next start position
    context.rotate(angle);

    // Render each letter and rotate for each
    for (let index = 0; index < length; index++) {
      let width = context.measureText(text[index]).width / 2;
      // Rotate by half of a letter width
      context.rotate(width / offset * clockwise);
      // Render the text
      context.fillText(text[index], 0, 0 - radius + size / 2);
      // Rotate by half of a letter width
      context.rotate((width + kerning) / offset * clockwise);
    }

    // This canvas will be drawn onto an existing canvas
    return canvas;

  }

  /**
   * A method to handle drawing an image on the canvas after loading it.
   * @param {String} url
   * @param {Number} left
   * @param {Number} top
   * @param {Number} width
   * @param {Number} height
   * @param {Function} callback
   */
  drawImage(url, left = 0, top = 0, width = 0, height = 0, callback = () => { }) {

    // Skip if no image URL is provided
    if (!url) {
      return;
    }

    // Load the image before drawing it
    this.loadImage(url, (image) => {
      // Use the specified width / height or fallback to the image size
      width = width || image.width;
      height = height || image.height;
      // Draw the image to the canvas context
      this.context.drawImage(image, left, top, width, height);
      // Fire the callback, passing the loaded image
      callback(image);
    });

  }

  /**
   * A method to handle drawing the solid background colour.
   * @param {String} style
   */
  drawBackground(style) {

    // Draw the background
    this.drawRectangle(
      {
        x: 0,
        y: 0,
        width: this.canvas.width,
        height: this.canvas.height,
      },
      style
    );

  }

  /**
   * A method to handle drawing a styled rectangle.
   * @param {Object} rectangle
   * @param {String} style
   */
  drawRectangle({ x, y, width, height }, style) {

    // Draw the rectangle
    this.context.fillStyle = style;
    this.context.fillRect(x, y, width, height);

  }

  /**
   * A method to load an image and pass it to the specified callback upong load.
   * @param {String} url
   * @param {Function} callback
   */
  loadImage(url, callback = () => { }) {

    // Skip if no image URL is provided
    if (!url) {
      return;
    }

    // Create the temporary image element
    const image = document.createElement("img");

    // Set the cross origin attribute to support external images
    image.crossOrigin = "anonymous";

    // Set the on load callback and bind the current context, passing the image
    image.onload = callback.bind(this, image);

    // Set the source URL to start loading, do this after setting the callback
    image.src = url;

  }

  /**
   * A method to export an image of the canvas as Base64 data.
   * @param {String} type
   */
  toDataURL(type = "image/png") {

    return this.canvas.toDataURL(type);

  }

  /**
   * A method to measure the text size.
   */
  measureText(text, font) {

    // Create a temporary canvas element to render the text to
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context.font = font;

    return context.measureText(text);

  }

  /**
   * A method to reset the canvas.
   */
  resetCanvas() {

    this.canvas.width = this.canvas.width;

  }

};
