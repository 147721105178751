import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import EventEmitter from './EventEmitter';

export default class Resources extends EventEmitter {
  constructor(sources) {
    super();

    // Options
    this.sources = sources;

    // Setup
    this.items = {};
    this.toLoad = this.sources.length;
    this.loaded = 0;

    this.setLoaders();
    this.startLoading();
  }

  setLoaders() {
    this.loaders = {};
    this.loaders.gltfLoader = new GLTFLoader();
    this.loaders.textureLoader = new THREE.TextureLoader();
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader();
    this.loaders.fontLoader = new FontLoader();
  }

  startLoading() {
    // Load each source
    this.sources.forEach((source) => {
      switch (source.type) {
        case 'gltfModel':
          this.loaders.gltfLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file);
            },

          );
          break;
        case 'texture':
          this.loaders.textureLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file);
            },
          );
          break;
        case 'cubeTexture':
          this.loaders.cubeTextureLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file);
            },
          );
          break;
        case 'font':
          this.loaders.fontLoader.load(
            source.path,
            (file) => {
              this.sourceLoaded(source, file);
            },
          );
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn(`Type ${source.type} cannot be loaded`);
      }
    });
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file;
    this.loaded++;

    if (this.loaded === this.toLoad) {
      // trigger ready event when all sources are loaded.
      this.trigger('ready');
    }
  }
}
