import * as THREE from 'three';
import Experience from '../Experience';

let instance = null;

export default class Jersey {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.sizes = this.experience.sizes;
    this.debug = this.experience.debug;

    this.resource = this.resources.items;

    this.normalScale = {
      x: 1,
      y: 1,
    };

    this.numberOpacity = {
      numberOpacity: 1,
    };

    this.baseColor = {
      baseColor: new THREE.Color('#808080'),
    };

    this.setModel();

    // Debug
    if (this.debug.active && instance === this) {
      this.debugFolder = this.debug.ui.addFolder('Jersey');

      this.debugFolder
        .add(this.model.position, 'y')
        .min(-2)
        .max(1)
        .step(0.001)
        .name('Jersey Y');

      this.debugFolder
        .add(this.model.position, 'z')
        .min(0)
        .max(2)
        .step(0.001)
        .name('Jersey Depth');

      this.debugFolder
        .add(this.model.rotation, 'y')
        .min(0)
        .max(Math.PI * 2)
        .step(0.001)
        .name('Jersey Rotation Y');

      this.debugFolder
        .add(this.model.rotation, 'x')
        .min(-Math.PI * 2)
        .max(Math.PI * 2)
        .step(0.001)
        .name('Jersey Rotation X');

      this.debugFolder
        .add(this.normalScale, 'x')
        .min(-2)
        .max(2)
        .step(0.001)
        .name('Jersey Normal X')
        .onChange(() => {
          this.model.traverse((child) => {
            if (child.isMesh) {
              child.material.normalScale = new THREE.Vector2(
                this.normalScale.x,
                this.normalScale.y,
              );
            }
          });
        });

      this.debugFolder
        .add(this.normalScale, 'y')
        .min(-2)
        .max(2)
        .step(0.001)
        .name('Jersey Normal Y')
        .onChange(() => {
          this.model.traverse((child) => {
            if (child.isMesh) {
              child.material.normalScale = new THREE.Vector2(
                this.normalScale.x,
                this.normalScale.y,
              );
            }
          });
        });

      this.debugFolder
        .add(this.numberOpacity, 'numberOpacity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Jersey Number Opacity')
        .onChange(() => {
          this.model.traverse((child) => {
            if (child.isMesh) {
              if (child.name === 'Number001') {
                child.material.opacity = this.numberOpacity;
              }
            }
          });
        });

      this.debugFolder
        .addColor(this.baseColor, 'baseColor')
        .name('Jersey Base Color')
        .onChange(() => {
          this.model.traverse((child) => {
            if (child.isMesh) {
              child.material.color = this.baseColor.baseColor;
            }
          });
        });
    }
  }

  setModel() {
    this.model = this.resource.jerseyModel.scene;

    this.model.scale.set(1.5, 1.5, 1.5);
    this.model.position.set(0, -1.53, 1.41);

    this.model.traverse((child) => {
      if (child.isMesh) {
        // child.castShadow = true;
        child.material.transparent = true;

        child.material.color = this.baseColor.baseColor;

        child.material.normalScale = new THREE.Vector2(
          this.normalScale.x,
          this.normalScale.y,
        );

        // child.material.normalMap = null;
        // child.material.roughnessMap = null;
        // child.material.metalnessMap = null;
        // child.material.map = null;

        if (child.name === 'Number001') {
          // fixes issue with material flashing artifacts
          child.material.alphaTest = 0.05;

          // change jersey number to 15
          child.material.map = this.resource.numberColor15;
          child.material.map.flipY = false;

          child.material.alphaMap = this.resource.numberOpacity15;
          child.material.alphaMap.flipY = false;
          // child.material.normalScale = new THREE.Vector2(0.7, 0.7);

          child.material.normalMap = this.resource.numberNormal15;
          child.material.normalMap.flipY = false;

          child.material.roughnessMap = this.resource.numberRoughness15;
          child.material.roughnessMap.flipY = false;

          child.material.metalnessMap = this.resource.numberMetalness15;
          child.material.metalnessMap.flipY = false;
        }
      }
    });

    this.scene.add(this.model);
  }
}
