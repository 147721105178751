import * as THREE from 'three';

import { gsap } from 'gsap';
import Experience from '../Experience';
import Environment from './Environment';
import Floor from './Floor';
import Jersey from './Jersey';
import Team from './Team';
import Text from './Text';
import Timeline from '../Timeline';

export default class World {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.sizes = this.experience.sizes;
    this.scroll = this.experience.scroll;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.fog = new THREE.Fog('#111111', 3.5, 7);
    this.interstitial = this.experience.interstitial;
    this.loader = this.experience.loader;
    this.camera = this.experience.camera;
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('World');

      this.debugFolder
        .add(this.fog, 'near')
        .min(0)
        .max(10)
        .step(0.001)
        .name('Fog Near');
      this.debugFolder
        .add(this.fog, 'far')
        .min(0)
        .max(10)
        .step(0.001)
        .name('Fog Far');
    }

    this.loader.on('loaded', () => {
      // Setup
      this.scene.fog = this.fog;
      this.floor = new Floor();
      this.jersey = new Jersey();
      this.team = new Team();
      this.text = new Text();
      this.environment = new Environment();
      this.setScale();

      // create once an reuse it

      // Set up scrolling timeline
      this.timeline = new Timeline(
        this.camera,
        this.jersey,
        this.team,
        this.text,
        this.scene.fog,
      );

      this.state = {};

      // animate team in a circle
      gsap.to(this.team.jerseyGroup.rotation, {
        y: Math.PI * 2,
        duration: 40,
        repeat: -1,
        ease: 'none',
        onUpdate: () => {
          this.team.jerseyGroup.traverse((child) => {
            if (child.isMesh) {
              const target = new THREE.Vector3();
              child.getWorldPosition(target);
              if (target.z > this.camera.instance.position.z - 1.85) {
                child.visible = false;
              } else {
                child.visible = true;
              }
            }
          });
        },
      });
    });
  }

  setScale() {
    // Resize jersey when screen is smaller
    if (this.sizes.width < 450) {
      // this.jersey.model.scale.set(1.25, 1.25, 1.25);

      // this.jersey.model.position.set(0, -1.2, 1.41);
    }
  }

  open() {
    // Prevent double open when viewing the press release on the about interstitial
    if (this.state.cameraPosition) return;
    this.state.cameraPosition = this.camera.instance.position.clone();
    // Hide jersey
    gsap.to(this.jersey.model, {
      opacity: 0,
      duration: 0.5,
      onUpdate: () => {
        this.jersey.model.traverse((child) => {
          if (child.isMesh) {
            child.material.opacity = this.jersey.model.opacity;
          }
        });
      },
    });

    // Hide team
    gsap.to(this.team.team, {
      opacity: 0,
      duration: 0.5,
      onUpdate: () => {
        this.team.jerseyGroup.traverse((child) => {
          if (child.isMesh) {
            child.material.opacity = this.team.team.opacity;
          }
        });
      },
    });

    // Hide text
    gsap.to(this.text.material, {
      opacity: 0,
      duration: 0.5,
    });

    // Hide content
    gsap.to('.content', {
      opacity: 0,
      duration: 0.5,
    });

    // Hide nav
    gsap.to(['.nav-bottom', '.nav-top'], {
      opacity: 0,
      duration: 0.5,
    });

    // Position camera

    if (this.sizes.width > 768) {
      gsap.to(this.camera.instance.position, {
        y: 0.4,
        z: 3.5,
        duration: 0.5,
      });
    } else {
      gsap.to(this.camera.instance.position, {
        y: 0.4,
        z: 2.7,
        duration: 0.5,
      });
    }
  }

  close() {
    // Show jersey
    gsap.to(this.jersey.model, {
      opacity: 1,
      duration: 0.5,
      onUpdate: () => {
        this.jersey.model.traverse((child) => {
          if (child.isMesh) {
            child.material.opacity = this.jersey.model.opacity;
          }
        });
      },
    });

    // Show team
    // TODO - make this value more dynamic
    if (this.scroll.y < 1000) {
      gsap.to(this.team.team, {
        opacity: 1,
        duration: 0.5,
        onUpdate: () => {
          this.team.jerseyGroup.traverse((child) => {
            if (child.isMesh) {
              child.material.opacity = this.team.team.opacity;
            }
          });
        },
      });
    }

    // Show text
    // TODO - make this value more dynamic
    if (this.scroll.y > 1000) {
      gsap.to(this.text.material, {
        opacity: 1,
        duration: 0.5,
      });
    }

    // Show content
    gsap.to('.content', {
      opacity: 1,
      duration: 0.5,
    });

    // Show nav
    gsap.to(['.nav-bottom', '.nav-top'], {
      opacity: 1,
      duration: 0.5,
    });

    if (this.state.cameraPosition) {
      // Position camera
      gsap.to(this.camera.instance.position, {
        y: this.state.cameraPosition.y,
        z: this.state.cameraPosition.z,
        duration: 0.5,
      });
    }

    // Reset state so open check works with a double open
    this.state.cameraPosition = null;
  }
}
