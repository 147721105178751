import * as THREE from 'three';
import Experience from '../Experience';
import Jersey from './Jersey';

export default class Team {
  constructor() {
    this.experience = new Experience();
    this.resources = this.experience.resources;
    this.jersey = new Jersey();
    this.scene = this.experience.scene;
    this.debug = this.experience.debug;

    this.setTeam();
  }

  setTeam() {
    this.team = {};
    this.jersey = this.jersey.model;

    this.jerseyGroup = new THREE.Group();

    this.team.count = 14;
    this.team.radius = 2.2;
    this.team.lookAt = {
      x: 0,
      y: 0.1,
      z: 0.1,
    };
    this.team.opacity = 1;

    // Create jerseys and add them to the group
    for (let i = 0; i < this.team.count; i++) {
      this.jerseyClone = this.jersey.clone();

      // new material for team jerseys
      this.jerseyClone.traverse((child) => {
        if (child.isMesh) {
          child.material = child.material.clone();
          child.material.transparent = true;
          child.material.opacity = this.team.opacity;

          // rotate to show the back of the jersey
          child.rotation.z = Math.PI;

          if (child.name === 'Number001') {
            child.position.z = 0.001;
            // fix for number transparency issue below 0.5
            // child.material.alphaTest = 0;

            // use simple material for numbers
            child.material.normalMap = null;
            child.material.roughnessMap = null;
            child.material.metalnessMap = null;

            child.material.roughness = 0.5;
            child.material.metalness = 0.8;

            // start with jersey number 14 and go down to 1
            const number = this.team.count - i;

            const colorKey = `numberColor${number}`;
            const opacityKey = `numberOpacity${number}`;

            // change jersey number
            child.material.map = this.resources.items[colorKey];
            child.material.map.flipY = false;

            child.material.alphaMap = this.resources.items[opacityKey];
            child.material.alphaMap.flipY = false;
          }

          // shadow casting
          child.castShadow = false;
        }
      });

      // Position each jersey around the center
      const angle = -(i / this.team.count) * Math.PI * 2;
      this.jerseyClone.position.x = Math.cos(angle) * this.team.radius;
      this.jerseyClone.position.z = Math.sin(angle) * this.team.radius;
      this.jerseyClone.position.y = 0;

      // Rotate each jersey to face the center
      this.jerseyClone.lookAt(this.team.lookAt.x, this.team.lookAt.y, this.team.lookAt.z);

      //

      this.jerseyGroup.add(this.jerseyClone);
    }
    this.scene.add(this.jerseyGroup);

    // Adjust the jersey group
    // this.jerseyGroup.rotation.y = -0.05;
    this.jerseyGroup.position.y = -1.5;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('Team');

      this.debugFolder
        .add(this.jerseyGroup.position, 'y')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Team Y');

      this.debugFolder
        .add(this.jerseyGroup.rotation, 'y')
        .min(-0.5)
        .max(0.5)
        .step(0.001)
        .name('Team Rotation');

      this.debugFolder
        .add(this.team, 'radius')
        .min(0)
        .max(5)
        .step(0.001)
        .name('Team Radius')
        .onChange(() => {
          for (let i = 0; i < this.team.count; i++) {
            const angle = -(i / this.team.count) * Math.PI * 2;
            this.jerseyClone = this.jerseyGroup.children[i];
            this.jerseyClone.position.x = Math.cos(angle) * this.team.radius;
            this.jerseyClone.position.z = Math.sin(angle) * this.team.radius;
          }
        });

      this.debugFolder
        .add(this.team.lookAt, 'z')
        .min(-1)
        .max(1)
        .step(0.001)
        .name('Team LookAt Z')
        .onChange(() => {
          for (let i = 0; i < this.team.count; i++) {
            this.jerseyClone = this.jerseyGroup.children[i];
            this.jerseyClone.lookAt(this.team.lookAt.x, this.team.lookAt.y, this.team.lookAt.z);
          }
        });

      this.debugFolder
        .add(this.team, 'opacity')
        .min(0)
        .max(1)
        .step(0.001)
        .name('Team Opacity')
        .onChange(() => {
          for (let i = 0; i < this.team.count; i++) {
            this.jerseyClone = this.jerseyGroup.children[i];
            this.jerseyClone.traverse((child) => {
              if (child.isMesh) {
                child.material.opacity = this.team.opacity;
              }
            });
          }
        });
    }
  }
}
