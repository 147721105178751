import './styles.scss';

import Experience from './Experience/Experience';

// eslint-disable-next-line no-unused-vars

// Load event fixes iOS Safari bug where the size of the canvas is set incorrectly
window.addEventListener('load', () => {
  const experience = new Experience(document.querySelector('canvas.webgl'));
});

const homeButton = document.querySelector('.logo');

homeButton.addEventListener('click', () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
});
