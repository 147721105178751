/* Vanback - Form Validator */

// Import the required module dependencies
import ProfaneWords from "./Utils/ProfaneWords";

/**
 * A class to handle validating the forms.
 */
export default class FormValidator {

  /**
   * The constructor for the form validator class to handle configuration.
   * @param {Object} options The options used to initialize the form validation.
   */
  constructor(options = {}) {

    // Skip if no form reference provided
    if (!options.form) {
      return;
    }

    // Get the options provided
    this.options = options;
    this.form = options.form;

    // State the default state
    this.valid = false;

    // Get references to form elements
    this.elements = this.form.elements;
    this.number = this.elements.number;
    this.name = this.elements.name;
    this.email = this.elements.email;

    // If the number field is present in the form, validate the value
    if (this.number) {
      // On input of the jersey number, validate the value
      this.number.addEventListener("input", this.validateJerseyNumberOnInput);
      // On blur of the jersey number, validate the value
      this.number.addEventListener("blur", this.validateJerseyNumberOnBlur);
    }

    // On blur of the jersey name, validate the value
    if (this.name) {
      this.name.addEventListener("blur", this.validateJerseyNameOnBlur);
    }

    // On input of the user's email address, validate the value
    if (this.email) {
      this.email.addEventListener("input", this.validateUserEmailOnInput);
    }

  }

  /**
   * A method to handle validating the form.
   */
  validateForm() {

    let valid = true;

    // Call all validation methods, binding the element context
    if (this.number) {
      this.validateJerseyNumberOnInput.call(this.number);
      this.validateJerseyNumberOnBlur.call(this.number);
    }
    if (this.name) {
      this.validateJerseyNameOnBlur.call(this.name);
    }
    if (this.email) {
      this.validateUserEmailOnInput.call(this.email);
    }

    // Iterate over all fields and check for any that are invalid
    for (let index = 0; index < this.elements.length; index++) {
      if (this.elements[index].dataset.valid === "false") {
        valid = false;
      }
    }

    // Cache the current valid state on the form
    this.valid = valid;

    // Return the valid state to the caller
    return valid;

  }

  /**
   * A method to handle validating the jersey number on field input.
   */
  validateJerseyNumberOnInput() {

    // Ensure only numbers are input
    this.value = this.value.replace(/[^0-9]/g, "");

  }

  /**
   * A method to handle validating the jersey number on field blur.
   */
  validateJerseyNumberOnBlur() {
    // Parse the value as an integer to compare with
    const value = window.parseInt(this.value, 10);
    const { min, max } = this;

    // Continue to validate if a valid number has been entered
    if (!window.isNaN(value)) {
      // Ensure the number is between 16-99, inclusive or remove it
      this.value = value < min || value > max ? "" : value;
      // Set the valid attribute on the field
      this.dataset.valid = this.value ? "true" : "false";
    } else {
      // Set the invalid attribute on the field
      this.dataset.valid = "false";
    }

  }

  /**
   * A method to handle validating the jersey name.
   */
  validateJerseyNameOnBlur() {

    let valid = true;

    // Get the min and max directly from the form elements
    const { elements } = this.form;
    const min = window.parseInt(elements.name.getAttribute("minlength"), 10);
    const max = window.parseInt(elements.name.getAttribute("maxlength"), 10);

    // If the text is too short or too long, it is invalid
    if ((min && this.value.length < min) || (max && this.value.length > max)) {
      valid = false;
    }

    // If the text contains profanity, it is invalid
    if (ProfaneWords.containsProfanity(this.value)) {
      valid = false;
    }

    // Set the valid attribute on the field
    this.dataset.valid = valid ? "true" : "false";

  }

  /**
   * A method to handle validating the user's email address format.
   */
  validateUserEmailOnInput() {

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let valid = true;

    // Simple and future-proof regex for email validation
    // Matches for something@something.something
    if (!regex.test(this.value)) {
      valid = false;
    }

    // Set the valid attribute on the field
    this.dataset.valid = valid ? "true" : "false";

  }

};
