/* Vanback - Player's Pledge */

// Import the required module dependencies
import FormValidator from './FormValidator';
import Request from './Utils/Request';

/**
 * A class to handle the player's pledge and contact form.
 */
export default class PlayersPledge {
  /**
   * The constructor for the class to handle configuration.
   * @param {Object} options The options used to initialize the instance.
   */
  constructor(options = {}) {
    // Cache the optionally provided options
    this.options = options;

    // Get references to the pages
    this.pledgePage = document.querySelector('.page--one');
    this.contactPage = document.querySelector('.page--two');
    this.contactForm = this.contactPage.querySelector('.page__inner--form');
    this.contactSuccess = this.contactPage.querySelector('.page__inner--success');

    // Get reference to the form
    this.form = this.contactForm.querySelector('form');

    // Create the validator instance for the form
    this.validator = new FormValidator({ form: this.form });

    // Get references to the fields / buttons
    const submitButton = this.contactForm.querySelector('button[type="submit"]');
    const closeButton = this.contactSuccess.querySelector('button');
    const backButton = this.contactPage.querySelector('.button__back');

    // On click of the pledge page, show the contact page
    this.pledgePage.addEventListener('click', this.showForm.bind(this));

    // On click of the submit button, show the success page
    submitButton.addEventListener('click', this.submitForm.bind(this));

    // On click of the close button, show the original player's pledge
    closeButton.addEventListener('click', this.resetForm.bind(this));

    // On click of the back button, show the original player's pledge
    backButton.addEventListener('click', this.resetForm.bind(this));
  }

  /**
   * Method to handle showing the contact form.
   */
  showForm(event) {
    // Prevent default functionality
    event.preventDefault();

    // show input fields
    this.contactForm.classList.remove('hidden');

    // TODO - explore delay for animation without using setTimeout
    setTimeout(() => {
      this.pledgePage.classList.remove('active');
      this.contactPage.classList.add('active');
    }, 400);

    this.pledgePage.classList.add('shuffle');
    this.contactPage.classList.remove('shuffle');

    // Set focus on the first form field for good user experience
    this.contactPage.querySelector('input').focus();
  }

  /**
   * Method to handle the validation and submission of the contact form.
   */
  async submitForm(event) {
    // Prevent default functionality
    event.preventDefault();

    // Get the form data from the input fields
    const data = {
      name: this.form.elements.name.value,
      email: this.form.elements.email.value,
      number: this.form.elements.number.value,
    };

    // Ensure the form is valid before continuing
    if (!this.validator.validateForm()) {
      return;
    }

    // TODO: Move options and URLs to a common config
    const endpoint = import.meta.env.VITE_FORM_SEND_API;

    // Handle the form submit functionality
    const response = await Request.post(endpoint, data);

    // Hide the contact form and show the success message on the same page
    this.contactForm.classList.add('hidden');
    this.contactSuccess.classList.remove('hidden');
  }

  /**
   * Method to handle resetting the form to the initial state.
   */
  resetForm(event) {
    // Prevent default functionality
    event.preventDefault();

    // Reset the state for the contact form
    this.contactForm.classList.remove('hidden');
    this.contactSuccess.classList.add('hidden');

    // TODO - explore delay for animation without using setTimeout
    setTimeout(() => {
      this.pledgePage.classList.add('active');
      this.contactPage.classList.remove('active');
    }, 400);

    // visually hide the contact form to avoid tabbing into it
    setTimeout(() => {
      this.contactForm.classList.add('hidden');
    }, 800);

    this.pledgePage.classList.remove('shuffle');
    this.contactPage.classList.add('shuffle');

    // Set focus on the join button for good user experience
    this.pledgePage.querySelector('button').focus();
  }
}
