export default [
  {
    name: 'environmentMapTexture',
    type: 'cubeTexture',
    path: [
      'textures/envMap/px.png',
      'textures/envMap/nx.png',
      'textures/envMap/py.png',
      'textures/envMap/ny.png',
      'textures/envMap/pz.png',
      'textures/envMap/nz.png',
    ],
  },
  {
    name: 'asphaltColorTexture',
    type: 'texture',
    path: 'textures/asphalt/color.jpg',
  },
  {
    name: 'asphaltNormalTexture',
    type: 'texture',
    path: 'textures/asphalt/normal.jpg',
  },
  {
    name: 'asphaltRoughnessTexture',
    type: 'texture',
    path: 'textures/asphalt/rough.jpg',
  },
  {
    name: 'floorLogoTexture',
    type: 'texture',
    path: 'textures/vanback-logo.png',
  },
  {
    name: 'jerseyModel',
    type: 'gltfModel',
    path: 'models/Jersey.glb',
  },
  {
    name: 'abcBingoFont',
    type: 'font',
    path: 'fonts/ABC_Bingo_Bold.json',
  },
  {
    name: 'numberColor1',
    type: 'texture',
    path: 'textures/numbers/1/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity1',
    type: 'texture',
    path: 'textures/numbers/1/T_Number1_OP.png',
  },

  {
    name: 'numberColor2',
    type: 'texture',
    path: 'textures/numbers/2/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity2',
    type: 'texture',
    path: 'textures/numbers/2/T_Number1_OP.png',
  },

  {
    name: 'numberColor3',
    type: 'texture',
    path: 'textures/numbers/3/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity3',
    type: 'texture',
    path: 'textures/numbers/3/T_Number1_OP.png',
  },

  {
    name: 'numberColor4',
    type: 'texture',
    path: 'textures/numbers/4/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity4',
    type: 'texture',
    path: 'textures/numbers/4/T_Number1_OP.png',
  },

  {
    name: 'numberColor5',
    type: 'texture',
    path: 'textures/numbers/5/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity5',
    type: 'texture',
    path: 'textures/numbers/5/T_Number1_OP.png',
  },

  {
    name: 'numberColor6',
    type: 'texture',
    path: 'textures/numbers/6/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity6',
    type: 'texture',
    path: 'textures/numbers/6/T_Number1_OP.png',
  },

  {
    name: 'numberColor7',
    type: 'texture',
    path: 'textures/numbers/7/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity7',
    type: 'texture',
    path: 'textures/numbers/7/T_Number1_OP.png',
  },

  {
    name: 'numberColor8',
    type: 'texture',
    path: 'textures/numbers/8/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity8',
    type: 'texture',
    path: 'textures/numbers/8/T_Number1_OP.png',
  },

  {
    name: 'numberColor9',
    type: 'texture',
    path: 'textures/numbers/9/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity9',
    type: 'texture',
    path: 'textures/numbers/9/T_Number1_OP.png',
  },

  {
    name: 'numberColor10',
    type: 'texture',
    path: 'textures/numbers/10/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity10',
    type: 'texture',
    path: 'textures/numbers/10/T_Number1_OP.png',
  },

  {
    name: 'numberColor11',
    type: 'texture',
    path: 'textures/numbers/11/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity11',
    type: 'texture',
    path: 'textures/numbers/11/T_Number1_OP.png',
  },

  {
    name: 'numberColor12',
    type: 'texture',
    path: 'textures/numbers/12/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity12',
    type: 'texture',
    path: 'textures/numbers/12/T_Number1_OP.png',
  },

  {
    name: 'numberColor13',
    type: 'texture',
    path: 'textures/numbers/13/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity13',
    type: 'texture',
    path: 'textures/numbers/13/T_Number1_OP.png',
  },

  {
    name: 'numberColor14',
    type: 'texture',
    path: 'textures/numbers/14/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity14',
    type: 'texture',
    path: 'textures/numbers/14/T_Number1_OP.png',
  },

  {
    name: 'numberColor15',
    type: 'texture',
    path: 'textures/numbers/15/T_Number1_BC.png',
  },
  {
    name: 'numberOpacity15',
    type: 'texture',
    path: 'textures/numbers/15/T_Number1_OP.png',
  },
  {
    name: 'numberMetalness15',
    type: 'texture',
    path: 'textures/numbers/15/T_Number1_M.png',
  },
  {
    name: 'numberRoughness15',
    type: 'texture',
    path: 'textures/numbers/15/T_Number1_R.png',
  },
  {
    name: 'numberNormal15',
    type: 'texture',
    path: 'textures/numbers/15/T_Number1_N.png',
  },

];
