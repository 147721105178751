import Experience from '../Experience';

export default class Screenshot {
  constructor() {
    this.experience = new Experience();
    this.canvas = this.experience.canvas;
    this.debug = this.experience.debug;
    this.renderer = this.experience.renderer;
    this.sizes = this.experience.sizes;

    if (this.debug.active) {
      const screenshot = {
        export: () => {
          // get screenshot from renderer at twice the resolution
          this.renderer.instance.setPixelRatio(2.0);
          this.renderer.instance.setSize(
            this.sizes.width * 2,
            this.sizes.height * 2,
          );

          // set camera aspect to match new resolution
          this.experience.camera.instance.aspect = this.sizes.width / this.sizes.height;
          this.experience.camera.instance.updateProjectionMatrix();

          // render scene at higher resolution
          this.renderer.instance.render(this.experience.scene, this.experience.camera.instance);

          const image = this.renderer.instance.domElement.toDataURL('image/png');

          const a = document.createElement('a');
          a.href = image;
          a.download = 'vanback-screenshot.png';
          a.click();

          // reset renderer
          this.renderer.instance.setPixelRatio(this.experience.renderer.pixelRatio);
          this.renderer.instance.setSize(this.sizes.width, this.sizes.height);

          // reset camera aspect
          this.experience.camera.instance.aspect = this.sizes.width / this.sizes.height;
          this.experience.camera.instance.updateProjectionMatrix();
        },
      };

      this.debug.ui.add(screenshot, 'export').name('Screenshot');
    }
  }
}
